.main_card_section {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 830px;
  background: #1f2333;
  border-radius: 20px;
  padding: 30px;
}
.sub_msg {
  display: none;
}

.main_card_section .activity_section {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  align-items: flex-start;
}

.activity_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #f2e4b6;
}
.activity_wrap {
  position: relative;
}

.activity_wrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 60px;
  width: 1px;
  height: 100%;
  background: linear-gradient(
    to bottom,
    #f1e4b600 0%,
    #f1e4b6 20%,
    #f1e4b6 50%,
    #f1e4b6 80%,
    #f1e4b600 100%
  );
  z-index: 0;
}
.activity_article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
}

.activity_article .activity_date {
  margin-top: 35px;
  display: flex;
  margin-bottom: 18px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #e8e6df;
}

.activity_article .first_activity_section {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-bottom: 6px;
}

.activity_article .first_activity_section .time {
  margin-left: 10px;
  margin-right: 17px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #7f8189;
}

/* .activity_article .first_activity_section .timeline {
    height: 70px;
    width: 1px;
    background: url('/public/images/inventory/timeline.png') no-repeat top/cover;
} */

.activity_result_box {
  width: 665px;
  background: #383c4e;
  border-radius: 8px;
  margin-left: 20px;
  display: flex;
  padding: 10px 12px 10px 13px;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  position: relative;
}
.activity_result_box:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -20.5px;
  transform: translate(0, -50%) rotate(45deg);
  width: 6px;
  height: 6px;
  background-color: #dacfa8;
  z-index: 1;
}

.activity_result_box .avatar {
  width: 42px;
  height: 42px;
  border: 3px solid #c4c4c4;
  border-radius: 100%;
}

.activity_result_box .result {
  margin-left: 10px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #9ea2b2;
}

.activity_result_box .focus_text {
  font-weight: 500;
  color: #ffffff;
}

.activity_article .middle_activity_section {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-bottom: 6px;
}

.activity_article .btn_view {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 107px;
  height: 28px;
  margin: 16px 12px 16px 12px;
  background: rgba(131, 135, 154, 0.2);
  cursor: pointer;
  border-radius: 4px;
}

.activity_article .btn_view span:nth-child(2) {
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a7abbb;
}

.activity_article .middle_activity_section .time {
  margin-left: 10px;
  margin-right: 17px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #7f8189;
}

/* .activity_article .middle_activity_section .timeline {
  height: 70px;
  width: 1px;
  background: url("/public/images/inventory/timeline.png") no-repeat top/cover;
  transform: rotate(180deg);
} */

.activity_article .last_activity_section {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.activity_article .last_activity_section .time {
  margin-left: 10px;
  margin-right: 17px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #7f8189;
}

/* .activity_article .last_activity_section .timeline {
  height: 70px;
  width: 1px;
  background: url("/public/images/inventory/timeline.png") no-repeat top/cover;
  transform: rotate(180deg);
} */
.time.mob {
  display: none !important;
}
@media all and (max-width: 1179px) {
  .inventory_container .my_nft_container {
    margin-top: 88px;
  }
  .main_card_section {
    gap: 50px;
    width: 100%;
    margin-top: 43px;
  }
  .activity_result_box {
    width: 100%;
    justify-content: flex-start;
  }
  .result {
    flex: 1;
    text-align: left;
  }
  .activity_article {
    width: 100%;
  }
  .activity_article > article {
    width: 100%;
  }
}

@media all and (max-width: 767px) {
  .time {
    display: none !important;
  }
  .activity_wrap:after {
    left: 1px;
  }

  .activity_result_box {
    display: grid;
    grid-template-columns: 47px 1fr;
    grid-template-rows: repeat(2, 1fr);
  }

  .time.mob {
    display: block !important;
  }
  .activity_article .btn_view {
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column: 1/3;
    grid-row: 2/3;
  }
  .activity_article .btn_view span:nth-child(2):nth-child(2) {
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(131, 135, 154, 0.2);
  }
  .main_card_section {
    padding: 30px 15px;
  }
  .main_card_section .activity_section .main_card_section {
    padding: 30px 14px;
  }
  .main_card_section .activity_section {
    min-height: 250px;
  }
  .inventory_container .my_nft_container .my_nft_list_section {
    flex-direction: column;
  }
  input[id="ch_my_nft"]:checked + label .my_nft_title,
  input[id="ch_my_nft"] + label .my_nft_title,
  input[id="ch_my_nft_list"]:checked + label .my_nft_list_title,
  input[id="ch_my_nft_list"] + label .my_nft_list_title,
  input[id="ch_activity"]:checked + label .activity_title,
  input[id="ch_activity"] + label .activity_title {
    height: 60px;
  }
}
