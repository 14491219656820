.modal_section .modal_wrap .modal_body {
  display: flex;
  height: auto;
  padding-top: 0px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 100px 0;
}

.modal_section .modal_wrap .modal_body .button_400 {
  margin-top: 66px;
}

.button_400 {
  width: 400px;
  height: 74px;
  display: flex;
  cursor: pointer;
  margin-top: 30px;
}

.button_400 .button_400_bg {
  position: relative;
  width: 100%;
  height: 74px;
  top: 2px;
  background: #b2a170;
  border-radius: 10px;
}

.button_400 .button_400_body {
  position: absolute;
  width: 400px;
  height: 72px;
  background: #f1e4b6;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.button_400 .button_400_body .button_400_text {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #472716;
}

@media all and (max-width: 1179px) {
  .modal_section .modal_wrap .modal_body {
    width: 90%;
    padding: 30px 0;
  }
  .modal_close {
    width: 90%;
  }
  .modal_section .modal_wrap {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}


@media all and (max-width: 767px) {
  .button_400 {
    width: 210px;
    height: 40px;
    display: flex;
    cursor: pointer;
    margin-top: 30px;
  }

  .button_400 .button_400_bg {
    position: relative;
    width: 100%;
    height: 40px;
    top: 2px;
    background: #b2a170;
    border-radius: 10px;
  }
  
  .button_400 .button_400_body {
    position: absolute;
    width: 210px;
    height: 38px;
    background: #f1e4b6;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
}