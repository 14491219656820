input[type="checkbox"] {
  display: none;
}

.main {
  width: 100%;
  height: auto;
  background: url("/public/images/main/login_03_fin.png") no-repeat top/cover;
  background-color: #0b0e1c;
}

section {
  width: 100%;
  min-height: 100vh;
}
.welcome_msg {
  justify-content: center;
  justify-items: center;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 55px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fffdf6;

  text-shadow: 0px 0px 40px rgba(255, 196, 82, 0.25), 0px 0px 20px #ffc452;
}

.sub_msg {
  height: 50px;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #fffdf6;
}

.nft_info_section {
  display: grid;
  grid-template: repeat(1, 1fr) / repeat(4, 1fr);
  margin-top: 50px;
  width: 100%;

  gap: 20px;
}
.nft_info_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;

  position: relative;
  width: 100%;
  /* background: url("/public/images/main/left_box.png") no-repeat center/cover; */
}
.nft_info_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
}

.floor_price span {
  width: 100%;
  align-items: center;
}

.nft_info_content .title {
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #f2e4b6;

  text-shadow: 0px 0px 40px #ffc452;
}

.floor_price .price {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-shadow: 0px 0px 40px #ffc452;
}

.total_volume span {
  width: 100%;
  align-items: center;
}

.total_volume .price {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-shadow: 0px 0px 40px #ffc452;
}

.highest_sell_price span {
  width: 100%;
  align-items: center;
}

.highest_sell_price .price {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-shadow: 0px 0px 40px #ffc452;
}

.total_listed_count span {
  width: 100%;
  align-items: center;
}

.total_listed_count .price {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  text-shadow: 0px 0px 40px #ffc452;
}

.main_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  margin-top: 90px;
  margin-bottom: 90px;
  height: auto;
  width: 100%;
}

.main_filter_section {
  height: auto;
  width: 270px;
  background: #161929;
  border-radius: 20px;
  padding: 30px;
  margin-right: 20px;
}

.filter_title {
  display: flex;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #f2e4b6;
}

.race_section {
  display: flex;
  flex-direction: column;
}

.race_section .race_title {
  display: flex;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #ffffff;
}

.race_section .race_filter_section {
  display: flex;
  margin-top: 15px;
  flex-direction: row;
}

.race_section .race_filter_section .filter_beny {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.race_section .race_filter_section .filter_beny .beny_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border: 1px solid #274864;
  border-radius: 4px;
}

input[id="ch_beny"] + label {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

input[id="ch_beny"] + label .beny_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border: 1px solid #274864;
  border-radius: 4px;
}

input[id="ch_beny"] + label .beny_badge .beny_icon {
  width: 44px;
  height: 44px;
  background: url("/public/images/main/symbol_beny.png");
  background-repeat: no-repeat;
  background-size: cover;
}

input[id="ch_beny"] + label .beny_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #6688a4;
  margin-top: 5px;
}

input[id="ch_beny"]:checked + label .beny_badge {
  background: url("/public/images/main/bc_highlight.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #cbcbcb !important;
}

input[id="ch_beny"]:checked + label .beny_badge .beny_icon {
  background: url("/public/images/main/hover_beny.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

input[id="ch_beny"]:checked + label .beny_title {
  color: #ffffff !important;
}

input[id="ch_beny"]:hover + label .beny_badge {
  background: url("/public/images/main/bc_highlight.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #cbcbcb !important;
}

input[id="ch_beny"]:hover + label .beny_badge .beny_icon {
  background: url("/public/images/main/hover_beny.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

input[id="ch_beny"]:hover + label .beny_title {
  color: #ffffff !important;
}

input[id="ch_arche"] + label {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

input[id="ch_arche"] + label .arche_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border: 1px solid #274864;
  border-radius: 4px;
}

input[id="ch_arche"] + label .arche_badge .arche_icon {
  width: 44px;
  height: 44px;
  background: url("/public/images/main/symbol_archee.png");
  background-repeat: no-repeat;
  background-size: cover;
}

input[id="ch_arche"] + label .arche_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #6688a4;
  margin-top: 5px;
}

input[id="ch_arche"]:checked + label .arche_badge {
  background: url("/public/images/main/bc_highlight.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #cbcbcb !important;
}

input[id="ch_arche"]:checked + label .arche_badge .arche_icon {
  background: url("/public/images/main/hover_archee.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

input[id="ch_arche"]:checked + label .arche_title {
  color: #ffffff !important;
}

input[id="ch_arche"]:hover + label .arche_badge {
  background: url("/public/images/main/bc_highlight.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #cbcbcb !important;
}

input[id="ch_arche"]:hover + label .arche_badge .arche_icon {
  background: url("/public/images/main/hover_archee.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

input[id="ch_arche"]:hover + label .arche_title {
  color: #ffffff !important;
}

input[id="ch_luchia"] + label {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

input[id="ch_luchia"] + label .luchia_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border: 1px solid #274864;
  border-radius: 4px;
}

input[id="ch_luchia"] + label .luchia_badge .luchia_icon {
  width: 44px;
  height: 44px;
  background: url("/public/images/main/symbol_luchia.png");
  background-repeat: no-repeat;
  background-size: cover;
}

input[id="ch_luchia"] + label .luchia_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #6688a4;
  margin-top: 5px;
}

input[id="ch_luchia"]:checked + label .luchia_badge {
  background: url("/public/images/main/bc_highlight.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #cbcbcb !important;
}

input[id="ch_luchia"]:checked + label .luchia_badge .luchia_icon {
  background: url("/public/images/main/hover_luchia.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

input[id="ch_luchia"]:checked + label .luchia_title {
  color: #ffffff !important;
}

input[id="ch_luchia"]:hover + label .luchia_badge {
  background: url("/public/images/main/bc_highlight.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #cbcbcb !important;
}

input[id="ch_luchia"]:hover + label .luchia_badge .luchia_icon {
  background: url("/public/images/main/hover_luchia.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

input[id="ch_luchia"]:hover + label .luchia_title {
  color: #ffffff !important;
}
.filter_wrap {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.rarity_section {
  display: flex;
  flex-direction: column;
}

.rarity_section .rarity_title {
  display: flex;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #ffffff;
}

.rarity_section .rarity_filter_section {
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  align-items: flex-start;
}

input[id="ch_epic"] + label {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

input[id="ch_epic"] + label .epic_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border: 1px solid #274864;
  border-radius: 4px;
}

input[id="ch_epic"] + label .epic_badge .epic_icon {
  width: 35px;
  height: 30px;
  background: url("/public/images/main/ic_epic.png");
  background-repeat: no-repeat;
  background-size: cover;
}

input[id="ch_epic"] + label .epic_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #6688a4;
  margin-top: 5px;
}

input[id="ch_epic"]:checked + label .epic_badge {
  border: 1px solid #f7d7ff;
  border-radius: 4px;
  background-color: #6c1253;
}

input[id="ch_epic"]:checked + label .epic_title {
  color: #ffffff !important;
}

input[id="ch_epic"]:hover + label .epic_badge {
  border: 1px solid #f7d7ff;
  border-radius: 4px;
  background-color: #6c1253;
}

input[id="ch_epic"]:hover + label .epic_title {
  color: #ffffff !important;
}

input[id="ch_sr"] + label {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

input[id="ch_sr"] + label .sr_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border: 1px solid #274864;
  border-radius: 4px;
}

input[id="ch_sr"] + label .sr_badge .sr_icon {
  width: 36px;
  height: 33px;
  background: url("/public/images/main/ic_super_rare.png");
  background-repeat: no-repeat;
  background-size: cover;
}

input[id="ch_sr"] + label .sr_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #6688a4;
  margin-top: 5px;
}

input[id="ch_sr"]:checked + label .sr_badge {
  background: #0d1856;
  border: 1px solid #d7e5ff;
  border-radius: 4px;
}

input[id="ch_sr"]:checked + label .sr_title {
  color: #ffffff !important;
}

input[id="ch_sr"]:hover + label .sr_badge {
  background: #0d1856;
  border: 1px solid #d7e5ff;
  border-radius: 4px;
}

input[id="ch_sr"]:hover + label .sr_title {
  color: #ffffff !important;
}

input[id="ch_r"] + label {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

input[id="ch_r"] + label .r_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border: 1px solid #274864;
  border-radius: 4px;
}

input[id="ch_r"] + label .r_badge .r_icon {
  width: 36px;
  height: 33px;
  background: url("/public/images/main/ic_rare.png");
  background-repeat: no-repeat;
  background-size: cover;
}

input[id="ch_r"] + label .r_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #6688a4;
  margin-top: 5px;
}

input[id="ch_r"]:checked + label .r_badge {
  background: #1a6557;
  border: 1px solid #d7ffdb;
  border-radius: 4px;
}

input[id="ch_r"]:checked + label .r_title {
  color: #ffffff !important;
}

input[id="ch_r"]:hover + label .r_badge {
  background: #1a6557;
  border: 1px solid #d7ffdb;
  border-radius: 4px;
}

input[id="ch_r"]:hover + label .r_title {
  color: #ffffff !important;
}

input[id="ch_n"] + label {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

input[id="ch_n"] + label .n_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border: 1px solid #274864;
  border-radius: 4px;
}

input[id="ch_n"] + label .n_badge .n_icon {
  width: 36px;
  height: 33px;
  background: url("/public/images/main/ic_normal.png");
  background-repeat: no-repeat;
  background-size: cover;
}

input[id="ch_n"] + label .n_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #6688a4;
  margin-top: 5px;
}

input[id="ch_n"]:checked + label .n_badge {
  background: #555555;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
}

input[id="ch_n"]:checked + label .n_title {
  color: #ffffff !important;
}

input[id="ch_n"]:hover + label .n_badge {
  background: #555555;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
}

input[id="ch_n"]:hover + label .n_title {
  color: #ffffff !important;
}

.main_card_section {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 830px;
  background: #1f2333;
  border-radius: 20px;
  padding: 30px;
  gap: 40px;
}

.sort_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sort_section .sort_result {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  color: #f2e4b6;
}

.sort_section .sort .sort_title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  color: #ffffff;
  margin-right: 8px;
}

.sort_section .sort .select {
  width: 133px;
  height: 30px;
  border: 1px solid #565b5f;
  border-radius: 100px;
  padding-left: 15px;
  background-color: #1f2333;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  color: #c4d3de;
  background: url("/public/images/main/sort_arrow.png") calc(100% - 20px) center
    no-repeat;
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0 none;
}

.sort_section .sort .select option {
  width: 133px;
  background: #1f2333;
}

select::-ms-expand {
  display: none;
}

.main_card_section .card_section {
  height: 100%;
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 15px;
  min-height: 1008px;
}

#empty {
  height: auto;
  width: auto;
  margin-top: 35px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  min-height: 500px;
}

.empty_list_text {
  height: auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: rgba(255, 255, 255, 0.6);
}

.main_card_section .card_section .nft_card {
  width: 182px;
  height: 310px;
  border: 2px solid #383c4e;
  border-radius: 8px;
  padding: 6px;
}

.nft_card:hover {
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(180deg, #13a338 0%, #1f3876 100%) 0 0 100% 0;
}

.main_card_section .card_section .nft_card_info {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}

.nft_card_gradient {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(11, 14, 28, 0) 31.21%,
    #0b0e1c 92.79%
  );
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.nft_card:hover .nft_card_gradient {
  background: linear-gradient(
    180deg,
    rgba(62, 45, 21, 0) 48.15%,
    rgba(62, 45, 21, 0.4) 60.4%,
    #816040 100%
  );
  background-repeat: no-repeat;
  background-size: cover;
}
.main_card_section .card_section .nft_card_info .nft_level_e {
  width: 34px;
  height: 17px;
  margin-left: 15px;
  background: url("/public/images/card/level_e.png");
}

.main_card_section .card_section .nft_card_info .nft_level_sr {
  width: 34px;
  height: 17px;
  margin-left: 15px;
  background: url("/public/images/card/level_sr.png");
}

.main_card_section .card_section .nft_card_info .nft_level_r {
  width: 34px;
  height: 17px;
  margin-left: 15px;
  background: url("/public/images/card/level_r.png");
}

.main_card_section .card_section .nft_card_info .nft_level_n {
  width: 34px;
  height: 17px;
  margin-left: 15px;
  background: url("/public/images/card/level_n.png");
}

.main_card_section .card_section .nft_card_info .nft_character_name {
  margin-top: 10px;
  font-family: "ITC New Baskerville";
  margin-left: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #f2e4b6;
}

.main_card_section .card_section .nft_card_info .nft_price_section {
  width: 100%;
  margin: 8px 0px 18px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.main_card_section
  .card_section
  .nft_card_info
  .nft_price_section
  .nft_current_price {
  height: 100%;
  display: flex;
  align-items: center;
}

.main_card_section
  .card_section
  .nft_card_info
  .nft_price_section
  .nft_current_price
  .nft_price_icon {
  width: 14px;
  height: 14px;
}

.main_card_section
  .card_section
  .nft_card_info
  .nft_price_section
  .nft_current_price
  .nft_price_text {
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  margin-left: 3px;
}

.main_card_section
  .card_section
  .nft_card_info
  .nft_price_section
  .nft_max_price {
  height: 100%;
  display: flex;
  align-items: center;
}
.main_card_section
  .card_section
  .nft_card_info
  .nft_price_section
  .nft_max_price
  .nft_max_price_text {
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #96a3ae;
}

.pagintaion_section {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
}

@media all and (max-width: 1179px) {
  .main {
    background: url("/public/images/main/login_03_fin.png") no-repeat
      top/contain;
    background-color: #0b0e1c;
  }
  .welcome_msg {
    font-size: 56px;
  }
  .sub_msg {
    font-size: 24px;
    margin-top: 26px;
  }
  .nft_info_section {
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    gap: 20px;
  }
  .nft_info_content .title {
    font-size: 16px;
  }
  .floor_price .price {
    font-size: 20px;
  }
  .nft_info_content {
    width: 354px;
  }
  .nft_info_content img {
    width: 100%;
    height: 74px;
  }

  .main_container {
    flex-direction: column;

    margin-top: 60px;
    margin-bottom: 60px;
  }

  .main_filter_section {
    width: 100%;

    background: #161929;

    padding: 20px 30px;
    margin-right: 0px;
  }
  .filter_wrap {
    display: flex;
    justify-content: space-between;
  }
  .race_section,
  .rarity_section {
    flex-direction: row;
    gap: 40px;
  }
  .filter_title {
    font-size: 28px;
  }
  .main_card_section .card_section {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: initial;
  }
  .main_card_section .card_section {
    min-height: 250px;
  }
}

@media all and (max-width: 768px) {
  .nft_info_section {
    grid-template: repeat(4, 1fr) / repeat(1, 1fr);
    gap: 20px;
  }
  .welcome_msg {
    font-size: 26px;
    line-height: 32px;
  }
  .sub_msg {
    font-size: 18px;
    margin-top: 30px;
  }
  .filter_wrap {
    flex-direction: column;
    gap: 45px;
    margin-top: 28px;
  }
  .race_section,
  .rarity_section {
    flex-direction: column;
    gap: 20px;
  }
  input[id="ch_luchia"] + label .luchia_badge,
  input[id="ch_arche"] + label .arche_badge,
  input[id="ch_beny"] + label .beny_badge {
    width: 55.41px;
    height: 55.41px;
  }
  input[id="ch_epic"] + label .epic_badge,
  input[id="ch_sr"] + label .sr_badge,
  input[id="ch_r"] + label .r_badge,
  input[id="ch_n"] + label .n_badge {
    width: 55.41px;
    height: 55.41px;
  }
  .sort_section {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  .nft_info_content {
    width: 100%;
  }
  #empty {
    min-height: 250px;
  }
}
