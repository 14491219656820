.main.gamebg {
  background: url("/public/images/game/bg_game.png") no-repeat top/cover;
  background-color: #0b0e1c;
}
.game_section {
  width: 100%;
}

.game_msg {
  justify-content: center;
  justify-items: center;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 55px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fffdf6;

  text-shadow: 0px 0px 40px rgba(255, 196, 82, 0.25), 0px 0px 20px #ffc452;
}

.game_info_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  margin-top: 90px;
  margin-bottom: 90px;
  height: 100%;
  width: 100%;
}

.game_info_container .menu_section {
  height: auto;
  width: 330px;
  background: #161929;
  border-radius: 20px;
  padding: 30px;
  margin-right: 20px;
  min-height: 873px;
}
label {
  display: flex;
  align-items: center;
  justify-content: center;
}
input[id="ch_game"] + label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(90deg, #272c3e 0%, #1f2333 100%);
  border-radius: 10px;
}
input[id="ch_game"] + label .game_title,
input[id="ch_account"] + label .account_title,
input[id="ch_wallet"] + label .wallet_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

input[id="ch_game"]:checked + label .game_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e8e6df;
  text-shadow: 0px 0px 40px rgba(255, 196, 82, 0.25), 0px 0px 20px #ffc452;
}

input[id="ch_game"]:checked + label,
input[id="ch_account"]:checked + label,
input[id="ch_wallet"]:checked + label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: url("/public/images/inventory/bg_active.png") no-repeat
    center/cover;
  border-radius: 10px;
}

input[id="ch_account"] + label {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;

  width: 100%;
  background: linear-gradient(90deg, #272c3e 0%, #1f2333 100%);
  border-radius: 10px;
}

input[id="ch_account"] + label .account_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

input[id="ch_account"]:checked + label .account_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e8e6df;
  text-shadow: 0px 0px 40px rgba(255, 196, 82, 0.25), 0px 0px 20px #ffc452;
}

input[id="ch_wallet"] + label {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;

  width: 100%;
  background: linear-gradient(90deg, #272c3e 0%, #1f2333 100%);
  border-radius: 10px;
}

input[id="ch_wallet"] + label .wallet_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

input[id="ch_wallet"]:checked + label .wallet_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e8e6df;
  text-shadow: 0px 0px 40px rgba(255, 196, 82, 0.25), 0px 0px 20px #ffc452;
}

.game_info_container .game_info_section {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 830px;
  background: #161929;
  border-radius: 20px;
  padding: 30px;
  align-items: center;
}

.game_info_container .game_info_section .download_title {
  width: 280px;
  height: 70px;
  background: url("/public/images/game/btn_download.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game_info_container .game_info_section .download_title span {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;
  color: #2e1a0d;
}

.game_info_container .game_info_section .download_info {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 30px;
}
.game_info_container .game_info_section .download_info img {
  width: 100%;
  object-fit: cover;
}
.game_info_container .game_info_section .how_to_play_title {
  display: flex;
  margin-top: 45px;
  align-items: center;
  flex-direction: column;
}

.game_info_container .game_info_section .how_to_play_title span {
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #f2e4b6;
}

.game_info_container .game_info_section .how_to_play_title p {
  width: 105px;
  height: 10px;
  background: url("/public/images/game/under_line.png");
  background-repeat: no-repeat;
  background-size: 105px, 3.5px;
}

.game_info_container .game_info_section .how_to_list_section {
  display: flex;
  gap: 13px;
  flex-direction: column;
  margin-top: 50px;
}

.game_info_container .game_info_section .how_to_list_section .how_to_install,
.game_info_container .game_info_section .how_to_list_section .how_to_wallet,
.game_info_container .game_info_section .how_to_list_section .how_to_game {
  width: 722px;
  height: 60px;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  border: 1px solid #d3cbaf;
  justify-items: center;
  align-items: center;
}

.how_to_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.how_to_left {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}
.game_info_container .game_info_section .how_to_list_section .how_symbol {
  width: 18px;
  height: 18px;
  background: url("/public/images/game/how_to_symbol.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

.game_info_container .game_info_section .how_to_list_section .how_title {
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #f2e4b6;
}

.game_info_container .game_info_section .how_to_list_section .how_arrow {
  width: 22px;
  height: 22px;
  margin-left: auto;
  color: #f2e4b6;
  background: url("/public/images/game/how_arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.info_section {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 830px;
  background: #161929;
  border-radius: 20px;
}
.info_section .account_info_section {
  padding: 30px;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: flex-start;
}

.info_section .wallet_info_section {
  padding: 30px;
  justify-content: flex-start;
  justify-items: flex-start;

  align-items: flex-start;
}

.info_section .wallet_info_section .wallet_info_title {
  display: flex;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 42px;
  color: #d3cbaf;
}

.account_info_section .account_info_title {
  display: flex;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 42px;
  color: #d3cbaf;
}

.wallet_connect_section {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: auto;
  height: 334px;
}

.wallet_connect_section .button_connect_wallet {
  width: 307px;
  height: 60px;
  background: url("/public/images/game/btn_wallet.png");
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.account_info_section .wallet_address_title_section {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.account_info_section .wallet_address_title_section .wallet {
  height: 23.764705657958984px;
  width: 25.25px;
  background: url("/public/images/game/ic_wallet.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}

.account_info_section .wallet_address_title_section span {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.account_info_section .wallet_address_section {
  margin-top: 12px;
  width: 100%;
}
.wallet_address_wrap {
  display: flex;
  align-items: center;
  width: 70%;
  border-radius: 10px;
  background: #1f2333;
  padding: 20px;
}
.account_info_section .wallet_address_section .address_name {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-right: 18px;
}

.account_info_section .wallet_address_section .address {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #b4bbde;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.account_info_section .wallet_token_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
}

.account_info_section .wallet_token_section .token_title_section {
  display: flex;
  justify-items: center;
  align-items: center;
}

.account_info_section .wallet_token_section .token_title_section .token {
  width: 30px;
  height: 30px;
  background: url("/public/images/game/ic_token.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 7px;
}

.account_info_section .wallet_token_section .token_title_section span {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.account_info_section .wallet_token_section .btn_sync {
  width: 67px;
  height: 29px;
  background: url("/public/images/game/ic_sync.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 20px;
}

.info_section .account_info_section .wallet_info_section {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  align-items: center;
  padding: 0;
  justify-content: space-around;
  width: 100%;
  background: #1f2333;
  border-radius: 10px;
}

.account_info_section .wallet_info_section .account_lort_section {
  display: flex;

  justify-content: center;
  justify-items: center;
  align-items: center;
}

.account_info_section .wallet_info_section .account_lort_section .lort_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  margin-right: 40px;
}

.account_info_section .wallet_info_section .account_lort_section .lort_result {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #f0b90b;
  margin-right: 27px;
}

.account_info_section .wallet_info_section .account_lort_section .button_claim,
.account_info_section .wallet_info_section .account_logt_section .button_claim {
  width: 111px;
  font-family: "Noto Sans CJK KR";
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1e4b6;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 700;
  color: #472716;
}

.account_info_section .wallet_info_section .account_logt_section {
  display: flex;

  justify-content: center;
  justify-items: center;
  align-items: center;
}

.account_info_section .wallet_info_section .account_logt_section .logt_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  margin-right: 40px;
}

.account_info_section .wallet_info_section .account_logt_section .logt_result {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #f0b90b;
  margin-right: 27px;
}

.game_info_container .info_section .nft_section {
  height: auto;
  width: 100%;
  background: #1f2333;
  border-radius: 20px;
  padding: 12px 35px 35px 35px;
}

.game_info_container .info_section .nft_msg {
  display: flex;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 42px;
  color: #f2e4b6;
  margin-bottom: 10px;
}

.game_info_container .info_section .nft_section .card_section {
  height: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 9px;
  margin-bottom: 40px;
  height: 80%;
  width: 100%;
}

.game_info_container .info_section .nft_section .card_section .nft_card {
  width: 100%;
  height: 229px;
  border: 2px solid #383c4e;
  border-radius: 8px;
  padding: 6px;
}

.nft_card:hover {
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(180deg, #13a338 0%, #1f3876 100%) 0 0 100% 0;
}

.game_info_container
  .info_section
  .nft_section
  .card_section
  .nft_card
  .nft_card_info {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.game_info_container
  .info_section
  .nft_section
  .card_section
  .nft_card
  .nft_card_gradient {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(11, 14, 28, 0) 31.21%,
    #0b0e1c 92.79%
  );
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
}

.game_info_container
  .info_section
  .nft_section
  .card_section
  .nft_card:hover
  .nft_card_gradient {
  background: linear-gradient(
    180deg,
    rgba(62, 45, 21, 0) 48.15%,
    rgba(62, 45, 21, 0.4) 60.4%,
    #816040 100%
  );
  background-repeat: no-repeat;
  background-size: cover;
}

.game_info_container
  .info_section
  .nft_section
  .card_section
  .nft_card
  .nft_card_info
  .nft_level_e {
  width: 34px;
  height: 17px;
  margin-left: 10px;
  background: url("/public/images/card/level_e.png");
}

.game_info_container
  .info_section
  .nft_section
  .card_section
  .nft_card_info
  .nft_level_sr {
  width: 34px;
  height: 17px;
  margin-left: 10px;
  background: url("/public/images/card/level_sr.png");
}

.game_info_container
  .info_section
  .nft_section
  .card_section
  .nft_card_info
  .nft_level_r {
  width: 34px;
  height: 17px;
  margin-left: 10px;
  background: url("/public/images/card/level_r.png");
}

.game_info_container
  .info_section
  .nft_section
  .card_section
  .nft_card_info
  .nft_level_n {
  width: 34px;
  height: 17px;
  margin-left: 10px;
  background: url("/public/images/card/level_n.png");
}

.game_info_container
  .info_section
  .nft_section
  .card_section
  .nft_card_info
  .nft_character_name {
  margin-top: 10px;
  font-family: "ITC New Baskerville";
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #f2e4b6;
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  margin-bottom: 8px;
}

.game_info_container .info_section .nft_section .button_withdraw {
  width: 100%;
  height: 31px;
  margin-top: 5px;
  background-color: #f1e4b6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_withdraw p {
  font-size: 18px;
  font-weight: 700;
  font-family: "Noto Sans CJK KR";
  color: #472716;
}
.pagintaion_section {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
}
input[id="ch_game"] + label,
input[id="ch_wallet"] + label,
input[id="ch_account"] + label,
input[id="ch_game"]:checked + label,
input[id="ch_wallet"]:checked + label,
input[id="ch_account"]:checked + label {
  height: 60px;
}

@media all and (max-width: 1179px) {
  .main.gamebg {
    background: url("/public/images/game/bg_game.png") no-repeat top/contain;
    background-color: #0b0e1c;
  }
  .game_info_container {
    width: 100%;
    flex-direction: column;
  }
  input[id="ch_game"]:checked + label,
  input[id="ch_account"]:checked + label,
  input[id="ch_wallet"]:checked + label {
    background: url("/public/images/inventory/bg_active-tab.png") no-repeat
      center/contain;
  }
  input[id="ch_game"] + label,
  input[id="ch_wallet"] + label,
  input[id="ch_account"] + label,
  input[id="ch_game"]:checked + label,
  input[id="ch_wallet"]:checked + label,
  input[id="ch_account"]:checked + label {
    height: 54px;
  }

  .game_info_container .menu_section {
    display: flex;
    height: auto;
    width: 100%;
    background-color: transparent;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    margin-right: 0;
    gap: 20px;
    min-height: 0;
    margin-bottom: 30px;
  }

  input[id="ch_game"] + label,
  input[id="ch_wallet"] + label,
  input[id="ch_account"] + label {
    width: 100%;
    flex: 1;
    margin-top: 0;
  }
  .game_info_container .game_info_section {
    width: 100%;
  }
  .game_info_container .game_info_section .how_to_list_section {
    width: 100%;
  }
  .game_info_container .game_info_section .how_to_list_section .how_to_install,
  .game_info_container .game_info_section .how_to_list_section .how_to_wallet,
  .game_info_container .game_info_section .how_to_list_section .how_to_game {
    width: 100%;
  }
  /* account */

  .button_withdraw p {
    font-size: 15px;
    font-weight: 700;
    font-family: "Noto Sans CJK KR";
    color: #472716;
  }
  .info_section .account_info_section .wallet_info_section {
    flex-direction: column;
    gap: 10px;
    background-color: transparent;
  }

  .account_info_section .wallet_info_section .account_lort_section,
  .account_info_section .wallet_info_section .account_logt_section {
    width: 100%;
    background-color: #1f2333;
    border-radius: 10px;
  }
}

@media all and (max-width: 767px) {
  input[id="ch_game"]:checked + label,
  input[id="ch_account"]:checked + label,
  input[id="ch_wallet"]:checked + label {
    background: url("/public/images/inventory/bg_active-mob.png") no-repeat
      center/contain;
  }

  .game_info_container .menu_section {
    flex-direction: column;
  }
  input[id="ch_game"] + label .game_title,
  input[id="ch_account"] + label .account_title,
  input[id="ch_wallet"] + label .wallet_title {
    height: 54px;
  }
  .how_to_left {
    gap: 10px;
  }
  .game_info_container .game_info_section .how_to_list_section .how_title {
    font-size: 18px;
  }
  .game_info_container .game_info_section .how_to_list_section .how_symbol {
    width: 20px;
    height: 20px;
  }
  .game_info_container .game_info_section {
    padding: 45px 9px;
  }
  .info_section .account_info_section {
    width: 100%;
  }

  /* account */
  .game_info_container .info_section .nft_section .card_section {
    grid-template-columns: repeat(2, 1fr);
  }
  .game_info_container .info_section .nft_section {
    padding: 12px 18px;
  }
  .wallet_address_wrap {
    width: 100%;
  }
  .address {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .account_info_section .wallet_info_section .account_lort_section,
  .account_info_section .wallet_info_section .account_logt_section {
    justify-content: space-around;
  }

  .account_info_section .wallet_info_section .account_lort_section .lort_title,
  .account_info_section .wallet_info_section .account_lort_section .lort_result,
  .account_info_section .wallet_info_section .account_lort_section .lort_title,
  .account_info_section .wallet_info_section .account_logt_section .logt_title,
  .account_info_section
    .wallet_info_section
    .account_logt_section
    .logt_result {
    margin-right: 0;
  }
  .info_section .account_info_section {
    padding: 30px 18px;
  }
  .account_info_section .wallet_info_section .account_lort_section .lort_title,
  .account_info_section .wallet_info_section .account_logt_section .logt_title {
    font-size: 16px;
  }
}
