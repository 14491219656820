input[type="radio"] {
  display: none;
}
.main {
}
/* .inventory_container {
  width: 100vw;
  min-height: 100vh;
 
  background-size: 100%, 100%, cover;
  background-repeat: no-repeat, no-repeat;
} */
.main.invenbg {
  background: url("/public/images/inventory/bg_inventory.png") no-repeat
    top/cover;
  background-color: #0b0e1c;
}
section {
  background: transparent;
}
.main_card_section .card_section .nft_card {
  width: 100%;
  cursor: pointer;
}
.main_card_section .card_section .nft_card_info {
  background-position: center;
}
.my_nft_msg {
  justify-content: center;
  justify-items: center;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 55px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fffdf6;

  text-shadow: 0px 0px 40px rgba(255, 196, 82, 0.25), 0px 0px 20px #ffc452;
}
.my_nft_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inventory_container .my_nft_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  margin-top: 90px;
  margin-bottom: 90px;
  height: 100%;
  width: 100%;
}

.inventory_container .my_nft_container .my_nft_list_section {
  height: auto;
  width: 330px;
  background: #161929;
  border-radius: 20px;
  padding: 30px;
  margin-right: 20px;
}

input[id="ch_my_nft"] + label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(90deg, #272c3e 0%, #1f2333 100%);
  border-radius: 10px;
}

input[id="ch_my_nft"] + label .my_nft_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
input[id="ch_my_nft"]:checked + label,
input[id="ch_my_nft_list"]:checked + label,
input[id="ch_activity"]:checked + label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: url("/public/images/inventory/bg_active.png") no-repeat
    center/contain;
  height: 60px;

  border-radius: 10px;
}

input[id="ch_my_nft"]:checked + label .my_nft_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e8e6df;
  text-shadow: 0px 0px 40px rgba(255, 196, 82, 0.25), 0px 0px 20px #ffc452;
}

input[id="ch_my_nft_list"] + label {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;

  width: 100%;
  background: linear-gradient(90deg, #272c3e 0%, #1f2333 100%);
  border-radius: 10px;
}

input[id="ch_my_nft_list"] + label .my_nft_list_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

input[id="ch_my_nft_list"]:checked + label .my_nft_list_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e8e6df;
  text-shadow: 0px 0px 40px rgba(255, 196, 82, 0.25), 0px 0px 20px #ffc452;
}

input[id="ch_activity"] + label {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;

  width: 100%;
  background: linear-gradient(90deg, #272c3e 0%, #1f2333 100%);
  border-radius: 10px;
}

input[id="ch_activity"] + label .activity_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

input[id="ch_activity"]:checked + label .activity_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e8e6df;
  text-shadow: 0px 0px 40px rgba(255, 196, 82, 0.25), 0px 0px 20px #ffc452;
}
input[id="ch_my_nft"] + label,
input[id="ch_my_nft"]:checked + label,
input[id="ch_my_nft_list"] + label,
input[id="ch_my_nft_list"]:checked + label,
input[id="ch_activity"] + label,
input[id="ch_activity"]:checked + label {
  height: 60px;
}

@media all and (max-width: 1179px) {
  .main.invenbg {
    background: url("/public/images/inventory/bg_inventory.png") no-repeat
      top/contain;
    background-color: #0b0e1c;
  }
  .inventory_container .my_nft_container .my_nft_list_section {
    background-color: transparent;
    padding: 0;
  }
  .inventory_container .my_nft_container {
    flex-direction: column;
  }
  .inventory_container .my_nft_container .my_nft_list_section {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-right: 0;
  }
  input[id="ch_my_nft"]:checked + label,
  input[id="ch_my_nft_list"]:checked + label,
  input[id="ch_activity"]:checked + label {
    background: url("/public/images/inventory/bg_active-tab.png") no-repeat
      center/contain;
  }
  input[id="ch_my_nft"] + label,
  input[id="ch_my_nft"]:checked + label,
  input[id="ch_my_nft_list"] + label,
  input[id="ch_my_nft_list"]:checked + label,
  input[id="ch_activity"] + label,
  input[id="ch_activity"]:checked + label {
    height: 54px;
    margin: 0;
  }
  input[id="ch_my_nft"]:checked + label .my_nft_title,
  input[id="ch_my_nft"] + label .my_nft_title,
  input[id="ch_my_nft_list"]:checked + label .my_nft_list_title,
  input[id="ch_my_nft_list"] + label .my_nft_list_title,
  input[id="ch_activity"]:checked + label .activity_title,
  input[id="ch_activity"] + label .activity_title {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: initial;
  }
  .main_card_section .card_section {
    grid-template-columns: repeat(3, 1fr);
  }
  .main_card_section .card_section .nft_card {
    width: 100%;
  }
}

@media all and (max-width: 767px) {
  input[id="ch_my_nft"]:checked + label,
  input[id="ch_my_nft_list"]:checked + label,
  input[id="ch_activity"]:checked + label {
    background: url("/public/images/inventory/bg_active-mob.png") no-repeat
      center/contain;
  }
  .main_card_section .card_section {
    grid-template-columns: repeat(2, 1fr);
  }
}
