body.active {
  overflow: hidden;
}
.menu {
  width: 100%;
  padding-top: 8px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  z-index: 9999;
  color: #ffffff;
}
.menu_list {
  cursor: pointer;
}
.logo {
  width: 189px;
  height: 65px;
  background: url("/public/images/menu/logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 22px;
}

.cursor_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.marketplace {
  width: 100px;
  margin-right: 50px;
}

.c_marketplace {
  width: 100px;
  margin-right: 50px;
  color: #f2e4b6;
}

.cursor {
  width: 100px;
  height: 5px;
  background: url("/public/images/main/under_line.png") no-repeat center/cover;
}

.comming {
  width: 67px;
  white-space: normal;
  margin-right: 50px;
  color: rgba(255, 255, 255, 0.6);
  cursor: auto;
}

.game {
  width: 100px;
  margin-right: 50px;
}

.c_game {
  width: 100px;
  margin-right: 50px;
  color: #f2e4b6;
}

.document {
  width: 100px;
  margin-right: 50px;
}

.c_document {
  width: 100px;
  margin-right: 50px;
  color: #f2e4b6;
}

.inventory {
  width: 100px;
  margin-right: 100px;
}

.c_inventory {
  width: 100px;
  margin-right: 100px;
  color: #f2e4b6;
}

.wallet {
  width: 180px;
  height: 42px;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #f2e4b6;
  border-radius: 10px;
  /* background: url("/public/images/menu/btn_wallet.png");
  background-repeat: no-repeat;
  background-size: cover; */
}

.wallet .ic_wallet {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.wallet .wallet_text {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #472716;
}
.mobMenu_btn {
  display: none;
}
.mobMenu {
  display: none;
}
@media all and (max-width: 1179px) {
  .menu {
    justify-content: space-between;
    padding: 8px 20px;
    line-height: 34px;
  }
  .logo,
  .mobMenu_btn {
    position: relative;
    z-index: 112;
  }
  .mobMenu_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 25px;
    gap: 5px;
  }
  .mobMenu_btn_list {
    width: 100%;
    height: 3px;
    background-color: #fff;
    transition: all 0.3s;
  }
  .mobMenu_btn_list:nth-child(1).active {
    transform: translate(0, 7px) rotate(45deg);
  }
  .mobMenu_btn_list:nth-child(2).active {
    opacity: 0;
  }
  .mobMenu_btn_list:nth-child(3).active {
    transform: translate(0, -9px) rotate(-45deg);
  }
  .menu_list {
    display: none;
  }
  .mobMenu {
    display: block;
    width: 100%;
    height: 0;
    position: fixed;
    top: 0;
    z-index: 111;
    left: 0;
    background: #0b0e1c;
    mix-blend-mode: normal;
    transition: height 0.3s;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobMenu.active {
    height: 100vh;
  }

  .mobMenu_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 29px;
    text-align: center;
    gap: 30px;
  }
  .mobMenu_list {
    margin-right: 0;
    width: auto;
  }
  .mobMenu_list.wallet {
    width: 180px;
  }
  .c_marketplace {
    margin-right: 0;
    width: auto;
  }
  .cursor {
    width: 100%;
    height: 7px;
    margin-top: 5px;

    background: url("/public/images/main/under_line.png") no-repeat
      center/contain;
  }
  .c_game,
  .marketplace,
  .c_inventory {
    width: auto;
    margin-right: 0;
  }

  .m_wallet {
    width: 270px;
    height: 80px;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #f2e4b6;
    border-radius: 10px;
    /* background: url("/public/images/menu/btn_wallet.png");
    background-repeat: no-repeat;
    background-size: cover; */
  }

  .m_wallet .ic_wallet {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  .m_wallet .wallet_text {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: #472716;
  }
}

@media all and (max-width: 767px) {
  .logo {
    width: 154px;
    height: 54px;
  }
  .marketplace,
  .mobMenu_list {
    font-size: 22px;
  }
  .m_wallet {
    width: 180px;
    height: 54px;
    gap: 5px;
  }
  .m_wallet .ic_wallet {
    margin-right: 0;
  }
  .m_wallet .wallet_text {
    font-size: 15px;
  }
}
