/* .main {
    width: 100vw;
    height: auto;
    background: linear-gradient(360deg, #0b0e1c 13.27%, rgba(11, 14, 28, 0) 100%),
      url("/public/images/main/login_03_fin.png");
    background-size: 100%, 100%, cover;
    background-repeat: no-repeat, no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

.detail_card_section {
  margin-top: 60px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.back_section {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 130px;
  height: 40px;
  background: #1f2333;
  border-radius: 8px 8px 0px 0px;
}

.back_image {
  border: none;
  width: 14px;
  height: 14px;
  margin-right: 20px;
  background: url("/public/images/detail/arrow.png");
}

.detail_card {
  display: flex;
  width: 1180px;
  height: 590px;
  background: #1f2333;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
  border-radius: 0px 8px 8px 8px;
}

.character_image {
  width: 482px;
  height: 590px;
  background: url("/public/images/card/card_01.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.detail_character_info {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.character_name_section {
  display: flex;
}

.character_name_section .name {
  align-items: flex-start;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 42px;
  margin-right: 10px;
  color: #d3cbaf;
}

.character_name_section .code_number {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 42px;

  color: #c6c6c6;
}

.wallet_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: flex-start;
}

.wallet_section .wallet_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9ea2b3;
}

.wallet_section .wallet_address_section {
  display: flex;
  margin-top: 12px;
  align-items: center;
  padding: 20px;
  height: 60px;
  width: 95%;
  margin-right: 40px;
  background: #161929;
  border-radius: 10px;
}

.wallet_section .wallet_address_section .address_name {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-right: 18px;
}

.wallet_section .wallet_address_section .address {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #b4bbde;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.attribute_section {
  height: 50px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.attribute_section .attribute_title {
  height: 50px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9ea2b3;
  margin-right: 17px;
  display: table-cell;
  padding-top: 20px;
}

.attribute_section .attribute_level {
  width: 100px;
  height: 50px;
  background: url("/public/images/detail/level_e.png");
  background-size: 100px, 32px;
}

.listing_price_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: flex-start;
}

.listing_price_section .price_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9ea2b3;
}

.listing_price_section .price_section {
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 60px;
  width: 95%;
  margin-right: 40px;
  background: #161929;
  border-radius: 10px;
}

.listing_price_section .price_section .price {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.listing_price_section .price_section .coin_section {
  display: flex;
  align-items: center;
}

.listing_price_section .price_section .coin_section .line {
  width: 2px;
  height: 40px;
  background: url("/public/images/detail/line.png") no-repeat center/cover;
  margin-right: 30px;
}
.listing_price_section .price_section .coin_section .icon_coin {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: url("/public/images/game/ic_token.png") no-repeat center/cover;
}

.listing_price_section .price_section .coin_section .unit {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-right: 10px;
}

.listing_price_section .price_section .coin_section .arrow {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  margin-right: 10px;
  background: url("/public/images/detail/ic_b_arrow.png") no-repeat
    center/contain;
}

.listing_price_section .price_info {
  margin-top: 14px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #575d73;
}

.button_sell_section {
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: flex-start;
}

.button_sell_section .button_sell {
  width: 280px;
  height: 55px;
  background: url("/public/images/detail/button_sell.png");
  background-repeat: no-repeat;
  background-size: cover;
}
