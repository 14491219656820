.footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  justify-items: center;
  width: 100%;
  height: 170px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #090714;
}

.company_info {
  font-weight: 400;
  line-height: 180%;
}

.sns_link_section {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 150px;
  height: 50px;
}

.twitter {
  width: 40px;
  height: 40px;
  background: url("/public/images/footer/twitter.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.discord {
  width: 40px;
  height: 40px;
  background: url("/public/images/footer/discord.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.medium {
  width: 40px;
  height: 40px;
  background: url("/public/images/footer/medium.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.footer_logo {
  width: 189px;
  height: 65px;
  background: url("/public/images/menu/logo.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.btn_top {
  width: 68px;
  height: 68px;
  right: 0;
  background: url("/public/images/footer/btn_top.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media all and (max-width: 768px) {
  .footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .footer_logo {
    width: 140px;
    height: 48px;
  }
  .sns_section {
    grid-column: 1/4;
    grid-row: 2/3;
  }
  .btn_top {
    grid-column: 3/4;
  }
}
