nav {
  height: auto;
  width: 100%;
  justify-content: space-between;

  display: flex;
  align-items: center;
}

.prev_button {
  border: none;
  display: flex;
  float: left;
  width: 30px;
  height: 30px;
  background: url("/public/images/main/prev_arrow.png");
}

.next_button {
  border: none;
  display: flex;
  float: right;
  width: 30px;
  height: 30px;
  background: url("/public/images/main/next_arrow.png");
}
.pagination_btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_button {
  border: none;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 12px 12px;
  background: #5e4c41;
  position: relative;
}

[aria-current]:not([aria-current="false"]) {
  border: none;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 12px 12px;
  background: #f3e4b7;
}
[aria-current]:not([aria-current="false"]):after {
  content: "";
  position: absolute;
  border: 1px solid #7a6659;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 24.75px;
  height: 24.75px;
  transition: all 0.3s;
}
