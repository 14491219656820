.detail_card_section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.back_section {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 130px;
  height: 40px;
  background: #1f2333;
  border-radius: 8px 8px 0px 0px;
}

.back_image {
  border: none;
  width: 14px;
  height: 14px;
  margin-right: 20px;
  background: url("/public/images/detail/arrow.png");
}

.detail_card {
  display: flex;
  width: 1180px;
  height: 590px;
  background: #1f2333;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
  border-radius: 0px 8px 8px 8px;
}

.character_image {
  width: 482px;
  height: 590px;
  background: url("/public/images/card/card_01.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.detail_character_info {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.character_name_section {
  display: flex;
}

.character_name_section .name {
  align-items: flex-start;
  font-family: "ITC New Baskerville";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 42px;
  margin-right: 10px;
  color: #d3cbaf;
}

.character_name_section .code_number {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 42px;

  color: #c6c6c6;
}

.wallet_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: flex-start;
}

.wallet_section .wallet_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9ea2b3;
}

.wallet_section .wallet_address_section {
  display: flex;
  margin-top: 12px;
  align-items: center;
  padding: 20px;
  height: 60px;
  width: 95%;
  margin-right: 40px;
  background: #161929;
  border-radius: 10px;
}

.wallet_section .wallet_address_section .address_name {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-right: 18px;
}

.wallet_section .wallet_address_section .address {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #b4bbde;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.attribute_section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: auto;
}

.attribute_section .attribute_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  height: auto;
  color: #9ea2b3;
  margin-right: 17px;
  padding-top: 0;
  display: table-cell;
}

.attribute_section .attribute_level {
  width: 100px;
  height: 50px;
  background: url("/public/images/detail/level_e.png");
  background-size: 100px, 32px;
}

.nft_listing_price_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: flex-start;
}

.nft_listing_price_section .price_title {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9ea2b3;
}

.nft_listing_price_section .price_section {
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  height: 60px;
  width: 95%;
  margin-right: 40px;
  background: #161929;
  border-radius: 10px;
}

.nft_listing_price_section .price_section .price {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.nft_listing_price_section .price_section .icon_coin {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: url("/public/images/game/ic_token.png") no-repeat center/cover;
}

.nft_listing_price_section .price_info {
  margin-top: 14px;
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #575d73;
}

.button_280 {
  width: 280px;
  height: 55px;
  display: flex;
  margin-top: 30px;
  cursor: pointer;
}

.button_280 .button_280_bg {
  position: relative;
  width: 100%;
  height: 55px;
  top: 2px;
  background: #b2a170;
  border-radius: 10px;
}

.button_280 .button_280_body {
  position: absolute;
  width: 280px;
  height: 53px;
  background: #f1e4b6;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.button_280 .button_280_body .button_280_text {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;

  color: #472716;
}
.sub_msg {
  display: block;
}
@media all and (max-width: 1179px) {
  .detail_card_section {
    margin: 60px 0;
  }
  .detail_card {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
  .character_image {
    height: 891px;
    width: 100%;
    background-position: top;
  }
  .button_buy_section {
    justify-content: center;
  }
  .button_280 {
    position: relative;
    width: 100%;
  }
  .button_280 .button_280_bg,
  .button_280 .button_280_body {
    width: 100%;
  }
}
@media all and (max-width: 767px) {
  .character_image {
    height: 392px;
  }
  .detail_character_info {
    padding: 40px 14px;
  }
  .wallet_section .wallet_address_section {
    height: auto;
  }
}
